<template>
  <VDialog
    class="br-12"
    max-width="1049"
    :value="dialog"
    @click:outside="close"
  >
    <VCard class="pa-6">
      <div class="d-flex justify-space-between">
        <div class="d-flex align-center">
          <p class="form-headline">
            {{ isEditing ? 'Редактировать синоним' : 'Создать синоним' }}
          </p>
        </div>
        <RoundButton
          color="#E5E5E5"
          elevation="0"
          icon="mdi-close"
          icon-color="#565656"
          :max-height="30"
          :max-width="30"
          src=""
          @click="close"
        />
      </div>
      <VContainer
        v-if="itemToEdit"
        class=""
        fuild
      >
        <VForm ref="form">
          <VRow class="d-flex">
            <VCol class="" cols="6">
              <p class="form-title mb-2">
                Текст поиска
              </p>
              <VTextField
                v-model="itemToEdit.alias"
                class="form-field type-text"
                color="success"
                outlined
                placeholder="Введите текст поиска"
                :rules="rules"
              />
            </VCol>
            <VCol cols="6">
              <p class="form-title mb-2">
                Тип синонима
              </p>
              <VRadioGroup
                v-model="itemToEdit.type"
                class="mt-0 pt-0"
                row
              >
                <VRadio
                  v-for="typ in types"
                  :key="typ.title"
                  class="pr-5"
                  color="success"
                  :label="typ.title"
                  :value="typ.value"
                />
              </VRadioGroup>
            </VCol>
          </VRow>
          <VRow>
            <VCol cols="4">
              <p class="form-title mb-2">
                Синоним
              </p>
              <SynonymsRequestInput
                v-if="itemToEdit"
                :keywords="itemToEdit.synonyms"
                @keyup="itemToEdit.synonyms = $event"
              />
            </VCol>
          </VRow>
        </VForm>
        <VRow class="mb-3 mt-8" justify="center">
          <VBtn
            class="default-btn cancel-btn elevation-0 mr-3"
            color="#E0E0E0"
            x-large
            @click="close"
          >
            Отменить
          </VBtn>
          <VBtn
            class="default-btn save-changes-btn elevation-0"
            color="success"
            x-large
            @click="save"
          >
            Сохранить
          </VBtn>
        </VRow>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script>
import RoundButton from '@/components/RoundButton.vue';
import SynonymsRequestInput from '@/components/SynonymsRequestInput.vue';

export default {
  components: { RoundButton, SynonymsRequestInput },
  props: {
    item: {
      type: Object,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'open'],
  data() {
    return {
      itemToEdit: { ...JSON.parse(JSON.stringify(this.item)) },
      key: 0,
      types: [{ value: 'food', title: 'Для блюд' }, { value: 'restaurant', title: 'Для заведений' }],
      rules: [
        (value) => !!value || 'Введите название',
      ],
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$emit(this.isEditing ? 'update' : 'create', this.itemToEdit);
        this.close();
      }
    },
    incrementKey() {
      this.key += 1;
    },
  },
  watch: {
    item(val) {
      this.itemToEdit = { ...JSON.parse(JSON.stringify(val)) };
    },
    dialog(val) {
      if (val) {
        this.incrementKey();
        this.$emit('open', this.key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/menu";

.save-changes-btn {
  width: 204px;
}
</style>
