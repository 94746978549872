<template>
  <VCard class="my-5 mx-8" flat>
    <VRow class="d-flex mt-2">
      <VCol class="d-flex" cols="6">
        <VSubheader class="mr-4 mb-0">
          Синонимы
        </VSubheader>
        <VBtn
          class="default-btn white--text"
          color="#4caf50"
          rounded
          @click="creatSynonymsModal()"
        >
          <VIcon left>
            mdi-plus-circle
          </VIcon>
          Добавить синоним
        </VBtn>
      </VCol>
    </VRow>
    <SynonymsRequestTable
      :items="items"
      @delete="deleteSynonymsModal"
      @edit="updateSynonymsModal"
      @search="alias = $event"
      @type="type = $event"
    />
    <VPagination
      v-model="page"
      class="mt-5"
      color="#B7BED0"
      :length="totalPagesCount"
      :total-visible="7"
    />
    <SynonymsRequestDialog
      :key="editDialogKey"
      :dialog="dialogEdit"
      :is-editing="checkId()"
      :item="item"
      @close="closeSynonymsModal"
      @create="createSynonyms"
      @update="updateSynonyms"
    />
    <DeleteDialog
      v-if="deleteItem"
      :id="deleteItem.id"
      :dialog="dialogDelete"
      :text-confirm="'Подтвердить'"
      :title="deleteItem.alias"
      @close="dialogDelete = false"
      @delete="deleteSynonyms"
    />
  </VCard>
</template>

<script>
import {
  fetchSynonymsRequest,
  deleteSynonymsRequest,
  updateSynonymsRequest,
  createSynonymsRequest,
} from '@/api/api';
import SynonymsRequestTable from '@/components/SynonymsRequestTable.vue';
import SynonymsRequestDialog from '@/components/SynonymsRequestDialog.vue';
import DeleteDialog from '@/components/DeleteDialog.vue';

export default {
  data() {
    return {
      items: [],
      item: null,
      page: 1,
      page_size: 10,
      type: null,
      alias: null,
      currentPage: 1,
      totalPagesCount: 1,
      dialogDelete: false,
      dialogEdit: false,
      editItem: false,
      deleteItem: null,
      isEditing: false,
      editDialogKey: 0,
    };
  },
  computed: {
    params() {
      const query = {
        page: this.page,
        page_size: this.page_size,
      };
      if (this.type) {
        query.type = this.type;
      }
      if (this.alias) {
        query.alias = this.alias;
      }
      return query;
    },
  },
  components: {
    SynonymsRequestTable,
    DeleteDialog,
    SynonymsRequestDialog,
  },
  created() {
    this.fetchSynonymsList();
  },
  methods: {
    async fetchSynonymsList() {
      await fetchSynonymsRequest(this.params).then((response) => {
        this.items = response.results;
        this.totalPagesCount = response.last_page;
      });
    },
    createSynonyms(item) {
      createSynonymsRequest(item).then(() => {
        this.item = null;
        this.fetchSynonymsList();
      });
    },
    updateSynonyms(item) {
      updateSynonymsRequest(item.id, item).then(() => {
        this.item = null;
        this.fetchSynonymsList();
      });
    },
    deleteSynonyms(id) {
      this.dialogDelete = false;
      deleteSynonymsRequest(id).then(() => {
        this.deleteItem = null;
        this.fetchSynonymsList();
      });
    },
    creatSynonymsModal() {
      this.item = {
        alias: '',
        synonyms: [],
        type: 'food',
      };
      this.dialogEdit = true;
      this.editDialogKey += 1;
      this.isEditing = false;
    },
    updateSynonymsModal(item) {
      this.item = item;
      this.dialogEdit = true;
      this.editDialogKey += 1;
      this.isEditing = true;
    },
    deleteSynonymsModal(item) {
      if (item) {
        this.deleteItem = item;
        this.dialogDelete = true;
      }
    },
    closeSynonymsModal() {
      this.item = null;
      this.dialogEdit = false;
    },
    checkId() {
      // eslint-disable-next-line no-prototype-builtins
      return this.item != null ? this.item.hasOwnProperty('id') : false;
    },
  },
  watch: {
    params: {
      immediate: false,
      handler() {
        this.fetchSynonymsList();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

*{
  scroll-behavior: smooth;
}

.v-subheader {
  font-weight: 550;
  font-size: 30px;
  line-height: 38px;
  color: #1F1F1F;
  margin-left: 0;
  padding-left: 0;
}

::v-deep{
  .col-12{
    padding-left: 0;
    padding-right: 0;
  }
}

</style>
