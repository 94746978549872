<template>
  <div class="chip-container">
    <div
      v-for="(chip, i) of chips"
      :key="chip.alias"
      class="chip"
    >
      {{ chip }}
      <VIcon
        color="#E43A35"
        small
        @click="deleteChip(i)"
      >
        mdi-close
      </VIcon>
    </div>
    <input
      v-model="currentInput"
      @keydown.delete="backspaceDelete"
      @keydown.tab.prevent="saveChip"
      @keypress.enter="saveChip"
      @keyup.space="saveChip"
    >
  </div>
</template>

<script>

export default {
  props: {
    set: {
      type: Boolean,
      default: true,
    },
    keywords: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['keyup'],
  data() {
    return {
      chips: [],
      currentInput: '',
    };
  },
  mounted() {
    this.chips = this.keywords;
  },
  methods: {
    saveChip() {
      const { chips, currentInput, set } = this;
      if ((set && !chips.includes(currentInput)) || !set) {
        chips.push(currentInput.replace(' ', ''));
      }
      this.currentInput = '';
      this.$emit('keyup', chips);
    },
    deleteChip(index) {
      this.chips.splice(index, 1);
    },
    backspaceDelete({ which }) {
      if (which === 8 && this.currentInput === '') {
        this.chips.splice(this.chips.length - 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chip-container {
  width: 410px;
  border: 1px solid rgb(165, 165, 165);;
  border-radius: 12px;
  min-height: 50px;
  display:flex;
  flex-wrap: wrap;
  align-content: space-between;
  padding: 3px 10px;
  .chip {
    background: #FFFFFF;
    border: 1px solid #DFE4EF;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 12px;
    gap: 8px;
    margin: 4px;
    .v-icon {
    cursor: pointer;
      font-size: 18px !important;
    }
  }
  &:hover {
    border: 1px solid black !important;
    opacity: 0.81;
  }
  &:focus-within {
    border: 2px solid #4caf50!important;
  }
  input {
    flex: 1 1 auto;
    width: 30px;
    border: none;
    outline: none;
    padding: 4px;
  }
}
</style>
