<template>
  <div>
    <VSimpleTable class="table">
      <thead>
        <tr v-if="headers.length" class="titles-thead">
          <th
            v-for="(row, index) in headers"
            :key="index"
            :class="row.classList"
            :style="row.styles || {}"
          >
            <span>{{ row.text }}</span>
          </th>
        </tr>
        <tr class="tr">
          <td class="py-3 text-start">
            <VTextField
              v-model="search"
              append-icon="mdi-chevron-down"
              class="filter-head"
              clearable
              color="#8c8c8c"
              dark
              dense
              hide-details
              prepend-inner-icon="mdi-magnify"
              solo
            />
          </td>
          <td class="text-start">
            <DropDownMenu
              :color="'#5B5B5B'"
              :items="states.synonymsTypeAll"
              :title="selectedType.title"
              @change="selectedType = $event"
            />
          </td>
          <td class="text-start" />
          <td class="text-start" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item) in items"
          :key="item.id"
          class="tr"
        >
          <td class="py-3 text-start">
            <span>
              {{ item.alias }}
            </span>
          </td>
          <td class="text-start">
            <span>
              {{ getTitleOfType(item.type) }}
            </span>
          </td>
          <td class="text-start">
            <span>
              {{ String(item.synonyms).replace(/,/g, ', ') }}
            </span>
          </td>
          <td>
            <div class="d-flex align-start pl-0 pr-0">
              <VBtn
                class="mr-3"
                color="white"
                elevation="0"
                fab
                hide-details
                max-height="35"
                max-width="35"
                @click="editSynonmym(item)"
              >
                <VImg
                  contain
                  height="45px"
                  :src="editWithBorder"
                  width="45px"
                />
              </VBtn>
              <VBtn
                color="white"
                elevation="0"
                fab
                hide-details
                max-height="35"
                max-width="35"
                @click="deleteSynonyms(item)"
              >
                <VImg
                  contain
                  height="45px"
                  :src="trashWithBorder"
                  width="45px"
                />
              </VBtn>
            </div>
          </td>
        </tr>
      </tbody>
    </VSimpleTable>
  </div>
</template>

<script>
import { synonymsRequestHeader } from '@/conditions/tablesHeaders';
import { states } from '@/conditions/states';
import trashWithBorder from '@/assets/icons/trashWithBorder.svg';
import editWithBorder from '@/assets/icons/editWithBorder.svg';
import DropDownMenu from '@/components/DropDownMenu.vue';

export default {
  props: {
    items: {
      type: Array,
      required: false,
    },
  },
  components: {
    DropDownMenu,
  },
  emits: ['edit', 'delete', 'search', 'type'],
  data() {
    return {
      states,
      types: states.synonymsType,
      headers: synonymsRequestHeader,
      trashWithBorder,
      editWithBorder,
      search: null,
      selectedType: { title: 'Все', value: null },
    };
  },
  methods: {
    getTitleOfType(type) {
      if (type) { return this.types.find((item) => item.value === type).title; }
      return '';
    },
    editSynonmym(item) {
      this.$emit('edit', item);
    },
    deleteSynonyms(item) {
      this.$emit('delete', item);
    },
  },
  watch: {
    search: {
      immediate: false,
      handler() {
        this.$emit('search', this.search);
      },
    },
    selectedType: {
      immediate: false,
      handler() {
        this.$emit('type', this.selectedType.value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

.tr>* {
  font-size: 14px !important;
}

::v-deep {
  .table {
    .v-data-table__wrapper {
      box-shadow: 0 0 1px 1px rgb(183 190 208 / 50%);
    }
    thead {
      .theme--light.v-text-field {
        background: #7F89A4;
        color: white;
        font-size: 10px;
      }
      .v-text-field--dense, .v-list--dense .v-list-item {
        min-height: 30px;
      }
      .v-icon.v-icon--dense {
        font-size: 15px;
      }
      .titles-thead {
        background-color: white !important;
        th {
          span {
            color: #1F1F1F !important;
            font-weight: 500;
          }
        }
      }
      .tr {
        background-color: #3E3E3E !important;
        color: white;
      }
      .filter-head {
        margin: 0px;
        padding: 0px;
        width: 60%;
      }
      .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
        min-height: 30px !important;
      }
      .v-input__slot {
        background-color: #5B5B5B !important;
        color: white !important;
      }
    }
  }
}
</style>
