<template>
  <div>
    <VMenu
      :disabled="disabled"
      max-height="500"
      nudge-top="30"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <VCard
          v-bind="attrs"
          class="d-flex justify-space-between align-center white--text px-2"
          :color="color"
          flat
          max-height="40"
          min-height="30"
          :style="`font-size: ${fontSize}px`"
          :width="width"
          v-on="on"
        >
          <VIcon
            v-if="prependIcon"
            color="white"
            dense
            v-text="prependIcon"
          />
          {{ selectedItem ? selectedItem.title : 'Выберите значение' }}
          <VIcon
            v-show="!disabled"
            color="white"
            dense
            v-text="'mdi-menu-down'"
          />
        </VCard>
      </template>
      <VList
        dense
        nav
        :style="listStyles"
      >
        <VListItemGroup
          v-model="selectedItem"
          dark
          mandatory
        >
          <VListItem
            v-for="(item, index) in items"
            :key="index"
            dense
            :ripple="false"
            :value="item"
            @click="updateSelectedItem(item)"
          >
            <VListItemTitle class="text-subtitle-1" v-text="item.title" />
          </VListItem>
        </VListItemGroup>
      </VList>
    </VMenu>
  </div>
</template>

<script>

export default {
  name: 'DropDownMenu',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '#7F89A4',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '125',
    },
    fontSize: {
      type: String,
      default: '12',
    },
  },
  emits: ['select', 'change'],
  data() {
    return {
      selectedItem: null,
    };
  },
  computed: {
    listStyles() {
      return {
        'background-color': this.color,
      };
    },
  },
  mounted() {
    if (this.title) {
      this.setSelectedItem();
    }
  },
  methods: {
    setSelectedItem() {
      this.selectedItem = this.items.find(
        (item) => item.title === this.title,
      );
    },
    updateSelectedItem(val) {
      this.selectedItem = val;
      this.$emit('select', val);
    },
  },
  watch: {
    title() {
      this.setSelectedItem();
    },
    selectedItem: {
      handler(val) {
        this.$emit('change', val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .theme--light.v-list {
    background: #7F89A4;
    color: white;
    font-size: 10px;
  }
  .v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 30px;
  }
  .v-icon.v-icon--dense {
    font-size: 15px;
  }
}
</style>
